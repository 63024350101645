:root {
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --border-radius: 12px;
  --stargreen: #00BF92;
  --stargreenlight:#ADD038;
  --starorange: #f67321;
  --starblack: #4D4D4D;
  --starblue: #0093ff;
  --starmaroon: #790408;
  --starblack2: #4b4b4d;
  --starwhite: #fff;
  --starcream: #ffeee3;
  --stargrey:#E6E7E8;
  --starbackgroundgreen: #f6f9f5;
  --starbackgroundblue: #e2f2fc;
  --starbackgroundmaroon: #fdedee;
  --starmaroonhighlight: #f7afae;
  --stargreenhighlight: #f0e9e2;
  --staryellow:#fbff07;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
