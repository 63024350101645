.mainContainer {
  margin: 0;
}
/* Slider2 Containers */
.slider2 {
  background-color: var(--starwhite);
  padding: 0rem 5rem;
}
.slider2 h1 {
  font-weight: bold;
  color: var(--stargreen);
}
.slider2 p {
  text-align: left;
  padding: 0 3rem;
  color: var(--starblack);
}
.slider2 img {
  padding: 2rem;
  width: 60%;
}
.slider2 button {
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--stargreen);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.slider2 button:hover {
  background-color: var(--starblack);
}

/* Slider3 Containers */
.slider3 {
  padding: 2rem;
  background-color: var(--starwhite);
}
.slider3 p {
  padding: 0rem 0.5rem;
  color: var(--starblack);
  text-align: left;
  line-height: 2rem;
}
.slider3 h1 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}

.slider3 h3 {
  font-size: 1.5rem;
  text-align: left;
  color: var(--starblack);
}
.slider3 li {
  padding: 0rem 0.5rem;
  color: var(--starblack);
  text-align: left;
  line-height: 2rem;
}
.slider3 img {
  width: 80%;
  border-radius: 1rem;
}
.bodytext {
  padding: 1rem;
}
.bodytextright {
  padding: 1rem;
}
.bodytext img {
  width: 20%;
  float: left;
  margin-right: 1rem;
}
.bodytextright img {
  width: 20%;
  float: right;
  margin-left: 1rem;
}
.slider3 button {
  /* Ensure button takes full width */
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starblue);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;

  /* Add margin for spacing */
}
.slider3 button:hover {
  background-color: var(--stargreen);
}

/* Slider4 Containers */

.slider4 {
  background-color: var(--starwhite);
  padding: 0rem 5rem;
}
.slider4 h1 {
  font-size: 4rem;
  color: var(--starblue);
}
.slider4 p {
  color: var(--starblack);
}

.slider4 h3 {
  font-weight: bold;
  color: var(--starblue);
}
.slider4 img {
  padding: 2rem;
  width: 60%;
}
.slider4 button {
  /* Ensure button takes full width */
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starblue);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;

  /* Add margin for spacing */
}
.slider4 button:hover {
  background-color: var(--stargreen);
}

/* Slider5 Containers */

.slider5 {
  background-color: var(--starblack);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.slider5Title {
  font-size: 1.5rem;
  color: var(--starblack);
}
.slider5container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.subContainer5Arrow {
  margin-left: -2.5rem;
  font-size: 10rem;
  color: var(--starblack);
}

.subContainer5Arrow:hover {
  color: var(--stargreen);
}

.subContainer5 {
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background-color: var(--starblack);
  line-height: 1rem;
}
.subSpan {
  color: var(--starwhite);
  text-wrap: nowrap;
  margin: 0 auto;
}

.slider5 .subContainer5:hover {
  background-color: var(--stargreen);
  cursor: pointer;
}

/* Slider6 Containers */
.slider6 {
  padding: 2rem;
  background-color: var(--starbackgroundgreen);
}
.slider6 p {
  padding: 2rem 5rem;
  color: var(--starblack);
}
.slider6 h1 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}
.slider6 img {
  width: 60%;
  border-radius: 1rem;
}
.slider6 button {
  width: 25%;
  font-size: large;
  font-weight: bold;
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--stargreen);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.arrowPointer6 {
  color: var(--stargreen);
  font-size: 2rem;
}
.slider6 h1:hover {
  color: var(--stargreen);
}
.slider6 button:hover {
  background-color: var(--starblack);
  color: var(--starwhite);
}
.arrowPointer6 :hover {
  color: var(--starblack);
}

/* Slider7 Containers */
.slider7 {
  padding: 2rem;
  background-color: var(--starbackgroundmaroon);
}
.slider7 p {
  padding: 0rem 5rem;
  color: var(--starblack);
}
.slider7 h1 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}
.slider7 img {
  margin-top: 1rem;
  width: 100%;
  border-radius: 1rem;
}
.slider7 button {
  width: 25%;
  font-size: large;
  font-weight: bold;
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starmaroon);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}

.arrowPointer7 {
  color: var(--starmaroon);
  font-size: 2rem;
}
.slider7 h1:hover {
  color: var(--starmaroon);
}
.slider7 button:hover {
  background-color: var(--starblack);
  color: var(--starwhite);
}
.arrowPointer7 :hover {
  color: var(--starblack);
}

/* Slider8 Containers */
.slider8 {
  padding: 0rem 5rem;
}
.slider8 p {
  padding: 0rem 5rem;
  color: var(--starblack);
  line-height: 2.5rem;
}
.slider8 li {
  color: var(--starblack2);
  line-height: 2.5rem;
}
.slider8Content {
  padding: 0rem 5rem;
}
.slider8 h1 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack2);
}
.slider8 h2 {
  font-size: 1.5rem;
  color: var(--starblack2);
}
.slider8 img {
  width: 80%;
  border-radius: 1rem;
}
.slider8 button {
  width: 25%;
  font-size: large;
  font-weight: bold;
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starblue);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.arrowPointer8 {
  color: var(--starblue);
  font-size: 2rem;
}
.slider8 h1:hover {
  color: var(--starblue);
}
.slider8 button:hover {
  background-color: var(--starblack);
  color: var(--starwhite);
}
.arrowPointer8 :hover {
  color: var(--starblack);
}

/* Slider9 Containers */
.slider9 {
  padding: 2rem;
  background-color: var(--starbackgroundgreen);
}

.slider9 h3 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}

.slider9 p {
  padding: 0rem 5rem;
  color: var(--starblack);
}

.slider9InputHolder {
  padding: 2rem 5rem;
  justify-content: center;
  align-items: center;
}
.slider9 input {
  margin: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--starblack);
  background: none;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}
.slider9 :focus {
  border-bottom: 2px solid rgb(223, 241, 188);
  box-shadow: 0 4px 2px -2px rgb(224, 247, 163);
  border-radius: 1rem;
  background-color: var(--stargreenhighlight);
  color: var(--starblack);
  transform: scale(1.1);
}
.slider9 textarea {
  margin: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--starblack);
  background: none;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}

.slider9leftholder {
  padding: 4rem 0rem;
}

.slider9 button {
  /* Ensure button takes full width */
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--stargreen);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}

.slider9 button:hover {
  background-color: var(--starblack);
}

/* Slider10 Containers */
.slider10 {
  padding: 2rem;
  background-color: var(--starwhite);
}

.slider10 h3 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}

.slider10 p {
  padding: 0rem 5rem;
  color: var(--starblack);
}

.slider10InputHolder {
  padding: 2rem 5rem;
  justify-content: center;
  align-items: center;
}
.slider10 input {
  margin: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--starblack);
  background: none;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}
.slider10 :focus {
  border-bottom: 2px solid rgb(244, 250, 245);
  box-shadow: 0 4px 2px -2px rgb(244, 250, 245);
  border-radius: 1rem;
  background-color: var(--starbackgroundgreen);
  color: var(--starblack);
  transform: scale(1.1);
}
.slider10 textarea {
  margin: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--starblack);
  background: none;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}

.slider10leftholder {
  padding: 4rem 0rem;
}

.slider10 button {
  /* Ensure button takes full width */
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starmaroon);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}

.slider10 button:hover {
  background-color: var(--starblack);
}

/* Common Containers */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}
.container7 {
  margin: 0 auto !important;
  padding: 0rem 10rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}
.container8 {
  margin: 0 auto !important;
  padding: 0rem 10rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}
/* Product Cards */
.productCard {
  text-align: center !important;
}

/* Signup Newsletter Maroon */

.signUp {
  background-color: var(--starmaroon);
}
.signUpSubcontainer {
  margin-top: 0.5rem;
  width: 50%;
  display: inline-flex;
  gap: 2rem;
}
.signUp h3 {
  margin: 0 auto;
  text-align: center;
  color: var(--starmaroonhighlight);
}
.signUp input {
  border: none;
  border-bottom: 2px solid white;
  background: none;
  color: #fff;
  padding: 0.8rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}
.signUp :focus {
  border-bottom: 2px solid rgb(233, 146, 161);
  box-shadow: 0 4px 2px -2px rgb(238, 146, 146);
  border-radius: 1rem;
  background-color: var(--starmaroonhighlight);
  color: var(--starblack);
  transform: scale(1.1);
}
.signUp button {
  display: block; /* Ensure button takes full width */
  background-color: var(--starmaroonhighlight);
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
  /* Add margin for spacing */
}

.signUp button:hover {
  color: #fff;
}

/* Signup Newsletter Green */

/* Footer */

.footer {
  margin: 2rem auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: var(--starwhite);
  font-size: small;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.footerHighlight img {
  float: left;
  width: 50%;
  animation: swing ease-in-out 2s infinite;
}

.footerHighlight img:hover {
  cursor: pointer;
}

/* Socials */
.socials {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
}
.socials span {
  font-size: 1rem;
}
.socials span:hover {
  width: 15%;
  transform: scale(1.1);
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
/* Form Submitted */
.submittedForm {
  text-align: center;
  margin-top: -8rem;
  color: var(--stargreen);
}

.successMessage{
  margin: 1rem;
  padding: 2rem;
  background-color: var(--stargreenhighlight);
  text-align: center;

}
.errorMessage{
  margin: 1rem;
  padding: 2rem;
  background-color: var(--starmaroonhighlight);
  text-align: center;
}
.socialshare{
  width:25%;
  text-align: center;
  justify-content: center;
  align-content: center;
}
/* ________________________________________________________________________________________________ */
@media (max-width: 1120px) {
  .productCard h1 {
    margin-top: 1rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }
  /* Slider 2 */
  .slider2 {
    padding: 2rem 1rem;
  }
  .slider2 p {
    width: 100%;
    padding: 0rem;
  }
  .slider2 img {
    width: 90%;
  }

  /* Slider 3 */
  .slider3 {
    padding: 1rem 1rem !important;
  }
  .slider3 p {
    padding: 0rem;
  }
  .slider3 img {
    width: 90%;
  }
  /* Slider 4 */
  .slider4 {
    padding: 1rem 1rem !important;
  }
  .slider4 p {
    padding: 0rem;
  }
  .slider4 img {
    width: 90%;
  }

  /* Slider5 */

  .slider5 {
    padding: 1rem;
  }
  .slider5 h1 {
    font-size: large;
  }
  .slider5 p {
    font-size: medium;
  }
  .subContainer5Arrow {
    margin-left: -1.5rem;
    font-size: 5rem;
  }

  /* Slider 6 */
  .slider6 {
    padding: 1rem 1rem !important;
  }
  .slider6 p {
    padding: 0rem;
  }
  .slider6 img {
    width: 90%;
  }

  /* Slider 7 */
  .slider7 {
    padding: 1rem 1rem !important;
  }
  .slider7 p {
    padding: 0rem;
  }
  .slider7 img {
    width: 100%;
  }
  /* Slider 8 */
  .slider8 {
    padding: 1rem 1rem !important;
  }
  .slider8 p {
    padding: 0rem;
  }
  .slider8 img {
    width: 100%;
  }
  /* Slider9 Containers */
  .slider9 {
    padding: 1rem;
  }

  .slider9 p {
    padding: 0rem;
  }

  .slider9InputHolder {
    width: 90%;
    padding: 0rem;
  }

  .slider9leftholder {
    padding: 0rem;
  }

  .slider9 button {
    /* Ensure button takes full width */
    width: 25%;
  }
}
/* ________________________________________________________________________________________________ */
@media (max-width: 700px) {
  /* Slider 2 */
  .slider2 {
    padding: 2rem 1rem;
  }
  .slider2 p {
    width: 100%;
    padding: 0rem;
  }
  .slider2 img {
    width: 90%;
  }
  /* Slider 3 */
  .slider3 {
    padding: 2rem 0rem !important;
  }
  .slider3 p {
    padding: 0rem;
  }
  .slider3 h1 {
    padding-top: 2rem;
    font-size: 1rem;
  }
  .slider3 img {
    width: 90%;
  }
  /* Slider 4 */
  .slider4 {
    padding: 0rem 0.5rem;
  }

  /* Slider5 */
  .slider5container {
    gap: 1rem;
  }
  .subContainer5 {
    padding: 0.5rem;
    line-height: 0.25rem;
  }

  .slider5 h1 {
    font-size: medium;
  }
  .slider5 p {
    font-size: smaller;
  }
  .subContainer5Arrow {
    margin-left: -1.5rem;
    font-size: 5rem;
  }

  /*Slider 6  */
  .slider6 {
    padding: 1rem 0rem !important;
  }
  .slider6 p {
    padding: 0rem;
  }
  .slider6 h1 {
    padding-top: 2rem;
    font-size: 1rem;
  }
  .slider6 img {
    width: 90%;
  }
  /* Slider7 */
  .slider7 {
    padding: 1rem 0rem !important;
  }
  .slider7 p {
    padding: 0rem;
  }
  .slider7 h1 {
    padding-top: 2rem;
    font-size: 1rem;
  }
  /* Slider7 */
  .slider8 {
    padding: 1rem 0rem !important;
  }
  .slider8 p {
    padding: 0rem;
  }
  .slider8 h1 {
    padding-top: 2rem;
    font-size: 1rem;
  }

  /* Common Containers */
  .container {
    display: block;
    padding: 0rem 2rem;
  }
  .container7 {
    padding: 2rem 2rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container8 {
    padding: 2rem 2rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container7 h3 {
    text-align: center;
  }
  .container8 h3 {
    text-align: center;
  }

  /* Slider9 Containers */
  .slider9 {
    padding: 1rem;
  }

  .slider9 p {
    padding: 0rem;
  }

  .slider9InputHolder {
    width: 90%;
    padding: 0rem;
  }

  .slider9leftholder {
    padding: 0rem;
  }

  .slider9 button {
    /* Ensure button takes full width */
    width: 25%;
  }

  /* Slider 10 */
  /* Slider10 Containers */
  .slider10 {
    padding: 0.5rem;
  }
  .slider10leftholder {
    padding: 0rem;
  }
  .slider10 p {
    padding: 0rem 0.5rem;
    color: var(--starblack);
  }

  /* ProductCard*/
  .productsCard {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .productCard h1 {
    margin-top: -2rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }

  /* SignUp NewsLetter*/
  .signUpSubcontainer {
    width: 90%;
  }
  .signUpSubcontainer8 {
    width: 90%;
  }
  /* Footer*/
  .footerHighlight img {
    float: initial;
    width: 50%;
  }
}
