/* Styles.css */
.container {
  justify-content: center;
  text-align: center;
  align-content: center;
}

.themesContainer {
  margin: 1rem;
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  gap: 20px;
}

.productCard {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.productCard:hover {
  cursor: pointer;
  border: solid 0.1rem var(--stargreen);
  background-color: var( --stargreenhighlight);
}
.gallery-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.galleryCard {
  width: 45%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  border-radius: 0.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.productCard img {
  max-width: 100%;
  height: auto;
}

.gallery-detail-container {
  text-align: center;
}

.gallery-slides {
  position: relative;
}

.gallery-slides img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.gallery-slides button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.gallery-slides button:hover {
  background-color: #555;
}
@media (max-width: 700px) {
  .themesContainer {
    margin: 0.5rem;
  padding: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
