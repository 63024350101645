.main {
  margin: 1rem;
  display: none;
}
.container {
  justify-content: center;
  text-align: center;
  align-content: center;
}
.themesContainer {
  margin: 1rem;
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}
.productCard {
  background-color: #ffffff;
  font-size: small;
  overflow: hidden;
  gap: 5rem;
}

/* Slider3 Containers */
.slider3 {
  padding: 2rem;
  background-color: var(--starwhite);
}
.slider3 p {
  padding: 0rem 0.5rem;
  color: var(--starblack);
  text-align: left;
  line-height: 2rem;
}
.slider3 h1 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}

.slider3 h3 {
  font-size: 1.5rem;
  text-align: left;
  color: var(--starblack);
}
.slider3 li {
  padding: 0rem 0.5rem;
  color: var(--starblack);
  text-align: left;
  line-height: 2rem;
}
.slider3 img {
  width: 80%;
  border-radius: 1rem;
}
.bodytext {
  padding: 1rem;
}
.bodytextright {
  padding: 1rem;
}
.bodytext img {
  width: 40%;
  float: left;
  margin-right: 1rem;
}

.bodytextright img {
  width: 40%;
  float: right;
  margin-left: 1rem;
}
.slider3 button {
  /* Ensure button takes full width */
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starblue);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;

  /* Add margin for spacing */
}
.slider3 button:hover {
  background-color: var(--stargreen);
}
.bodytext3 {
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bodytext3 img {
  width: 60%;
}

/* Slider4 Containers */
.slider4 {
  padding: 2rem;
  background-color: var(--starwhite);
}
.slider4 p {
  padding: 0rem 0.5rem;
  color: var(--starblack);
  text-align: left;
  line-height: 2rem;
}
.slider4 h1 {
  font-size: 2rem;
  text-align: center;
  color: var(--starblack);
}

.slider4 h3 {
  font-size: 1.5rem;
  text-align: left;
  color: var(--starblack);
}
.slider4 li {
  padding: 0rem 0.5rem;
  color: var(--starblack);
  text-align: left;
  line-height: 2rem;
}
.slider4 img {
  width: 80%;
  border-radius: 1rem;
}
.bodytext {
  padding: 1rem;
}
.bodytextright {
  padding: 1rem;
}
.bodytext img {
  width: 15%;
  float: left;
  margin-right: 1rem;
}
.bodytextright img {
  width: 15%;
  float: right;
  margin-left: 1rem;
}

.slider4 button {
  /* Ensure button takes full width */
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--starblue);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;

  /* Add margin for spacing */
}
.slider4 button:hover {
  background-color: var(--stargreen);
}

/* ________________________________________________________________________________________________ */
@media (max-width: 1120px) {
  /* Slider 3 */
  .slider3 {
    padding: 1rem 1rem !important;
  }
  .slider3 p {
    padding: 0rem;
  }
  .slider3 img {
    width: 90%;
  }
  .slider4 {
    padding: 1rem 1rem !important;
  }
  .slider4 p {
    padding: 0rem;
  }
  .slider4 img {
    width: 90%;
  }
}
/* ________________________________________________________________________________________________ */
@media (max-width: 700px) {
  /* Slider 3 */
  .slider3 {
    padding: 2rem 0rem !important;
  }
  .slider3 p {
    padding: 0rem;
  }
  .slider3 h1 {
    padding-top: 2rem;
    font-size: 1rem;
  }
  .slider3 img {
    width: 90%;
  }
  .slider4 {
    padding: 2rem 0rem !important;
  }
  .slider4 p {
    padding: 0rem;
  }
  .slider4 h1 {
    padding-top: 2rem;
    font-size: 1rem;
  }
  .slider4 img {
    width: 90%;
  }
  .themesContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
